import MUIHeader from './components/MUIHeader'
import MUIHeroSection from './components/MUIHeroSection'
import MUICallToBuySection from './components/MUICallToBuySection'
import MUIFooter from './components/MUIFooter'
import MUIPresentationSection from './components/MUIPresentationSection'
import MUIShowProductsSection from './components/MUIShowProductsSection'
import MUIWhereIsSection from './components/MUIWhereIsSection'
import { MUIMainHome } from './styles/global'

const App = () => {
  return (
    <>
      <MUIHeader />
      <MUIMainHome>
        <MUIHeroSection />
        <MUIPresentationSection />
        <MUIShowProductsSection />
        <MUIWhereIsSection />
        <MUICallToBuySection />
      </MUIMainHome>
      <MUIFooter />
    </>
  )
}

export default App
