import { styled } from '@mui/material'

export const MUIStyledHeader = styled('header')(({ theme }) => ({
  width: '100%',
  height: 'fit-content',
}))

export const MUIStyledLogo = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: '320px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '280px',
  },
}))

export const MUIStyledHeaderContent = styled('div')(({ theme }) => ({
  height: '120px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    height: '100px',
  },
}))
