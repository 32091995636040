import { MUIGlobalContainer } from '../../styles/global'
import { buildImage } from '../../utils/cloudinary'
import MUIButton from '../MUIButton'
import { callToBuySectionMockProps } from './mocks'
import {
  MUIStyledSection,
  MUIStyledContent,
  MUIStyledAbsolutBg,
  MUIStyledImageBg,
} from './styles'

const { backgroundImageDesktop } = callToBuySectionMockProps

const MUICallToBuySection = () => {
  return (
    <MUIStyledSection>
      <MUIStyledAbsolutBg>
        <MUIStyledImageBg
          src={buildImage(backgroundImageDesktop)
            // @ts-ignore
            .resize('w_1455,h_548')
            .toURL()}
          alt={'callBg'}
        />
      </MUIStyledAbsolutBg>
      <MUIGlobalContainer>
        <MUIStyledContent>
          <MUIButton />
        </MUIStyledContent>
      </MUIGlobalContainer>
    </MUIStyledSection>
  )
}

export default MUICallToBuySection
