import { IPresentationSection } from './types'

export const presentationSectionMockProps: IPresentationSection = {
  title: '35 anos de tradição',
  description:
    'A Gás Minas Brasil tem uma equipe qualificada e pronta para atender você em todas as fases do processo. Desde a compra, transporte e armazenamento a instalação e manutenção, garantindo um serviço completo e de qualidade',
  imagesSlider: [
    'gmb-assets/slider03_wyalvx',
    'gmb-assets/new-assets-2024/gas_-16_j6xo7q',
    'gmb-assets/img02_sygh9o',
    'gmb-assets/new-assets-2024/gas_-37_jvwqa5',
    'gmb-assets/new-assets-2024/UltraGás-2_q7wxai',
    'gmb-assets/new-assets-2024/gas_-11_uucugg',
    'gmb-assets/new-assets-2024/gas_-27_wtbyc1',
    'gmb-assets/img08_r7g1sg',
    'gmb-assets/new-assets-2024/gas_-06_ckopl0',
    'gmb-assets/new-assets-2024/gas_-40_sshom3',
    'gmb-assets/new-assets-2024/gas_-14_luu75g',
    'gmb-assets/img03_np8qct',
    'gmb-assets/new-assets-2024/UltraGás-7_addhzf',
    'gmb-assets/new-assets-2024/CAPA_2_ullok3',
    'gmb-assets/new-assets-2024/gas_-18_whva1h',
    'gmb-assets/new-assets-2024/gas_-25_w50cis',
  ],
}
