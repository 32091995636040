import { useTheme } from '@mui/material'
import { buttonMockProps } from './mocks'
import {
  MUIStyledButtonLabel,
  MUIStyledButton,
  MUIStyledLink,
  IconWhatsapp,
} from './styles'
import { IButtonProps } from './types'

const { button } = buttonMockProps

const MUIButton = ({ primary }: IButtonProps) => {
  const theme = useTheme()
  return (
    <MUIStyledLink href={button.href}>
      <MUIStyledButton
        sx={
          primary
            ? {
                backgroundColor: theme.customPalette.primary.main,
                color: theme.customPalette.background.default,
                '&:hover': {
                  backgroundColor: theme.customPalette.primary.hover,
                },
              }
            : {
                backgroundColor: theme.customPalette.background.main,
                color: theme.customPalette.primary.main,
                '&:hover': {
                  opacity: '.85',
                },
              }
        }
      >
        <MUIStyledButtonLabel>
          <IconWhatsapp
            sx={
              primary
                ? {
                    path: {
                      fill: theme.customPalette.background.default,
                    },
                  }
                : {
                    path: {
                      fill: theme.customPalette.primary.main,
                    },
                  }
            }
          />
          {button.label}
        </MUIStyledButtonLabel>
      </MUIStyledButton>
    </MUIStyledLink>
  )
}

export default MUIButton
