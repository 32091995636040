import { MUIGlobalContainer } from '../../styles/global'
import { buildImage } from '../../utils/cloudinary'
import MUIButton from '../MUIButton'
import { heroSectionMockProps } from './mocks'
import {
  MUIStyledHeroSection,
  MUIStyledHeroContent,
  MUIStyledHeroCard,
  MUIStyledHeroDescription,
  MUIStyledHeroTitle,
  MUIStyledAbsolutBg,
  MUIStyledImageBg,
} from './styles'

const { description, title, backgroundImage } = heroSectionMockProps

const MUIHeroSection = () => {
  return (
    <MUIStyledHeroSection>
      <MUIStyledAbsolutBg>
        <MUIStyledImageBg
          src={buildImage(backgroundImage)
            // @ts-ignore
            .resize('w_1440,h_837')
            .toURL()}
          alt="hero"
        />
      </MUIStyledAbsolutBg>
      <MUIGlobalContainer>
        <MUIStyledHeroContent>
          <MUIStyledHeroCard>
            <MUIStyledHeroTitle>{title}</MUIStyledHeroTitle>
            <MUIStyledHeroDescription>{description}</MUIStyledHeroDescription>
          </MUIStyledHeroCard>
          <MUIButton />
        </MUIStyledHeroContent>
      </MUIGlobalContainer>
    </MUIStyledHeroSection>
  )
}

export default MUIHeroSection
