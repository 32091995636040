import { MUIGlobalContainer } from '../../styles/global'
import { presentationSectionMockProps } from './mocks'

import {
  MUIDescription,
  MUIFlagContent,
  MUIInfoflag,
  MUIPresentationContainer,
  MUISliderContainer,
  MUISliderContent,
  MUISliderImage,
  MUISliderImageContent,
  MUITitle,
} from './styles'

import { Swiper, SwiperSlide } from 'swiper/react'

import { Autoplay, Navigation } from 'swiper'
import { buildImage } from '../../utils/cloudinary'

const { title, description, imagesSlider } = presentationSectionMockProps

const MUIPresentationSection = () => {
  return (
    <MUIPresentationContainer>
      <MUIInfoflag>
        <MUIGlobalContainer>
          <MUIFlagContent>
            <MUITitle>{title}</MUITitle>
            <MUIDescription>{description}</MUIDescription>
          </MUIFlagContent>
        </MUIGlobalContainer>
      </MUIInfoflag>

      <MUISliderContainer>
        <MUISliderContent>
          <Swiper
            // autoplay={{
            //   delay: 3000,
            //   disableOnInteraction: false,
            // }}
            loop={true}
            centeredSlides={true}
            breakpoints={{
              '0': {
                slidesPerView: 1,
              },
              '550': {
                slidesPerView: 2,
              },
              '1200': {
                slidesPerView: 4,
              },
            }}
            navigation={true}
            modules={[Navigation, Autoplay]}
            className="mySwiper"
          >
            {imagesSlider.map((image, index) => (
              <SwiperSlide key={index}>
                <MUISliderImageContent>
                  <MUISliderImage
                    src={buildImage(image)
                      // @ts-ignore
                      .resize('w_1000,h_1000')
                      .toURL()}
                    alt={'alt'}
                  />
                </MUISliderImageContent>
              </SwiperSlide>
            ))}
          </Swiper>
        </MUISliderContent>
      </MUISliderContainer>
    </MUIPresentationContainer>
  )
}

export default MUIPresentationSection
