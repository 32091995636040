import { IShowProductsSection } from './types'

export const showProductsSectionMockProps: IShowProductsSection = {
  title: 'Mais qualidade, segurança e preço justo em Divinópolis',
  description:
    'Oferecemos uma seleção diversificada de botijões, proporcionando o tamanho ideal para cada demanda em sua casa ou negócio.',
  imageUrl: 'gmb-assets/botijoes_g36zse',
  topics: [
    {
      svg: (
        <svg
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M29.5 27.25C23.825 25.775 22 24.25 22 21.875C22 19.15 24.525 17.25 28.75 17.25C32.3 17.25 34.075 18.6 34.725 20.75C35.025 21.75 35.85 22.5 36.9 22.5H37.65C39.3 22.5 40.475 20.875 39.9 19.325C38.85 16.375 36.4 13.925 32.5 12.975V11.25C32.5 9.175 30.825 7.5 28.75 7.5C26.675 7.5 25 9.175 25 11.25V12.9C20.15 13.95 16.25 17.1 16.25 21.925C16.25 27.7 21.025 30.575 28 32.25C34.25 33.75 35.5 35.95 35.5 38.275C35.5 40 34.275 42.75 28.75 42.75C24.625 42.75 22.5 41.275 21.675 39.175C21.3 38.2 20.45 37.5 19.425 37.5H18.725C17.05 37.5 15.875 39.2 16.5 40.75C17.925 44.225 21.25 46.275 25 47.075V48.75C25 50.825 26.675 52.5 28.75 52.5C30.825 52.5 32.5 50.825 32.5 48.75V47.125C37.375 46.2 41.25 43.375 41.25 38.25C41.25 31.15 35.175 28.725 29.5 27.25Z"
            fill="white"
          />
        </svg>
      ),
      text: 'Melhor preço da região',
    },
    {
      svg: (
        <svg
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M32.5 20C34.4891 20 36.3968 20.7902 37.8033 22.1967C39.2098 23.6032 40 25.5109 40 27.5C40 28.163 40.2634 28.7989 40.7322 29.2678C41.2011 29.7366 41.837 30 42.5 30C43.163 30 43.7989 29.7366 44.2678 29.2678C44.7366 28.7989 45 28.163 45 27.5C45 24.1848 43.683 21.0054 41.3388 18.6612C38.9946 16.317 35.8152 15 32.5 15C31.837 15 31.2011 15.2634 30.7322 15.7322C30.2634 16.2011 30 16.837 30 17.5C30 18.163 30.2634 18.7989 30.7322 19.2678C31.2011 19.7366 31.837 20 32.5 20Z"
            fill="white"
          />
          <path
            d="M32.5 10C37.1413 10 41.5925 11.8437 44.8744 15.1256C48.1563 18.4075 50 22.8587 50 27.5C50 28.163 50.2634 28.7989 50.7322 29.2678C51.2011 29.7366 51.837 30 52.5 30C53.163 30 53.7989 29.7366 54.2678 29.2678C54.7366 28.7989 55 28.163 55 27.5C55 21.5326 52.6295 15.8097 48.4099 11.5901C44.1903 7.37053 38.4674 5 32.5 5C31.837 5 31.2011 5.26339 30.7322 5.73223C30.2634 6.20107 30 6.83696 30 7.5C30 8.16304 30.2634 8.79893 30.7322 9.26777C31.2011 9.73661 31.837 10 32.5 10Z"
            fill="white"
          />
          <path
            d="M54.375 39.775C54.2375 39.3737 54.0001 39.0139 53.6852 38.7296C53.3703 38.4454 52.9883 38.2459 52.575 38.15L37.575 34.725C37.1678 34.6327 36.744 34.6438 36.3423 34.7573C35.9405 34.8709 35.5736 35.0832 35.275 35.375C34.925 35.7 34.9 35.725 33.275 38.825C27.883 36.3404 23.5625 32.0021 21.1 26.6C24.275 25 24.3 25 24.625 24.625C24.9168 24.3264 25.1291 23.9595 25.2427 23.5577C25.3562 23.156 25.3673 22.7322 25.275 22.325L21.85 7.5C21.7541 7.08674 21.5546 6.70468 21.2704 6.38978C20.9861 6.07488 20.6263 5.83751 20.225 5.7C19.6412 5.49147 19.0383 5.34075 18.425 5.25C17.7931 5.10349 17.1483 5.01975 16.5 5C13.45 5 10.5249 6.2116 8.36827 8.36827C6.2116 10.5249 5 13.45 5 16.5C5.01323 26.7068 9.07372 36.4917 16.291 43.709C23.5083 50.9263 33.2932 54.9868 43.5 55C45.0102 55 46.5056 54.7025 47.9009 54.1246C49.2961 53.5467 50.5639 52.6996 51.6317 51.6317C52.6996 50.5639 53.5467 49.2961 54.1246 47.9009C54.7025 46.5056 55 45.0102 55 43.5C55.0008 42.8637 54.9506 42.2283 54.85 41.6C54.7449 40.979 54.586 40.3684 54.375 39.775ZM43.5 50C34.6173 49.9934 26.1003 46.4618 19.8192 40.1808C13.5382 33.8997 10.0066 25.3827 10 16.5C10.0066 14.7781 10.6935 13.1286 11.9111 11.9111C13.1286 10.6935 14.7781 10.0066 16.5 10H17.325L20 21.6L18.65 22.3C16.5 23.425 14.8 24.325 15.7 26.275C17.1656 30.4239 19.5369 34.1943 22.6418 37.3122C25.7467 40.4301 29.5072 42.8172 33.65 44.3C35.75 45.15 36.575 43.575 37.7 41.4L38.425 40.025L50 42.675V43.5C49.9934 45.2219 49.3065 46.8714 48.0889 48.0889C46.8714 49.3065 45.2219 49.9934 43.5 50Z"
            fill="white"
          />
        </svg>
      ),
      text: 'Atendimento especializado',
    },
    {
      svg: (
        <svg
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.5 47.5C20.2614 47.5 22.5 45.2614 22.5 42.5C22.5 39.7386 20.2614 37.5 17.5 37.5C14.7386 37.5 12.5 39.7386 12.5 42.5C12.5 45.2614 14.7386 47.5 17.5 47.5Z"
            stroke="white"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M42.5 47.5C45.2614 47.5 47.5 45.2614 47.5 42.5C47.5 39.7386 45.2614 37.5 42.5 37.5C39.7386 37.5 37.5 39.7386 37.5 42.5C37.5 45.2614 39.7386 47.5 42.5 47.5Z"
            stroke="white"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.5 42.5H7.5V32.5M5 12.5H32.5V42.5M22.5 42.5H37.5M47.5 42.5H52.5V27.5M52.5 27.5H32.5M52.5 27.5L45 15H32.5"
            stroke="white"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 22.5H17.5"
            stroke="white"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      text: 'Entrega rápida',
    },
  ],
}
