import { styled } from '@mui/material'

export const MUIStyledFooter = styled('footer')(({ theme }) => ({
  width: '100%',
}))

export const MUIStyledFooterContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem 0',
  gap: '1.5rem',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
  },
}))

export const MUIStyledCopyright = styled('p')(({ theme }) => ({
  fontSize: theme.customTypography.body2.fontSize,
  fontWeight: theme.customTypography.body2.fontWeight,
  color: theme.customPalette.text.primary,
  textAlign: 'center',
}))

export const MUIStyledLogo = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: '175px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '320px',
  },
}))
