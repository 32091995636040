import { styled } from '@mui/material'

export const MUIPresentationContainer = styled('div')(({ theme }) => ({
  width: '100%',
  paddingTop: '2.5rem',
}))

export const MUIInfoflag = styled('div')(({ theme }) => ({
  backgroundColor: theme.customPalette.primary.main,
  padding: '2rem 0',
}))

export const MUIFlagContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '2rem',
  textAlign: 'center',
}))

export const MUITitle = styled('h2')(({ theme }) => ({
  fontSize: theme.customTypography.h2.fontSize,
  fontWeight: theme.customTypography.h2.fontWeight,
  lineHeight: theme.customTypography.h2.lineHeight,
  color: theme.customPalette.background.default,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.customTypography.h3.fontSize,
    fontWeight: theme.customTypography.h3.fontWeight,
    lineHeight: theme.customTypography.h3.lineHeight,
  },
}))

export const MUIDescription = styled('p')(({ theme }) => ({
  maxWidth: '1000px',
  fontSize: theme.customTypography.body1.fontSize,
  fontWeight: theme.customTypography.body1.fontWeight,
  lineHeight: '35px',
  color: theme.customPalette.background.default,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.customTypography.body2.fontSize,
    fontWeight: theme.customTypography.body2.fontWeight,
    lineHeight: theme.customTypography.body2.lineHeight,
  },
}))

export const MUISliderContainer = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '1532px',
  margin: '0 auto',
  padding: '0 1rem',
}))

export const MUISliderContent = styled('div')(({ theme }) => ({
  padding: '4rem 0',
  '& .swiper-button-prev': {
    backgroundColor: theme.customPalette.secondary.main,
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    color: theme.customPalette.background.default,
    opacity: '.65',

    ':after': {
      fontSize: '22px !important',
      fontWeight: '700',
      marginRight: '3px',
    },
  },
  '& .swiper-button-next': {
    backgroundColor: theme.customPalette.secondary.main,
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '50%',
    color: theme.customPalette.background.default,
    opacity: '.65',

    ':after': {
      fontSize: '22px !important',
      fontWeight: '700',
      marginLeft: '3px',
    },
  },
  [theme.breakpoints.down('md')]: {
    padding: '2rem 0',
  },
}))

export const MUISliderImageContent = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '400px',
  margin: '0 auto',
  padding: '1rem',
  aspectRatio: '1/1',
}))

export const MUISliderImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  borderRadius: '10px',
}))
