import { Cloudinary } from '@cloudinary/url-gen'

const cld = new Cloudinary({
  cloud: {
    cloudName: 'dlgjwkjte',
  },
  url: {
    secure: true,
  },
})

export const buildImage = (src) => {
  return cld.image(src).quality('auto').format('auto')
}
